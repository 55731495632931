import './reset.css';
import './variable.css';
import './style.css';
import './inputbox.css';
import './responsive.css';

import { useEffect, useState } from "react";

import failed from "./assets/failed.svg";
import successful from "./assets/successful.svg";
import hidden from "./assets/hidden.gif";
import logo from "./assets/logo.png";
import logo_gif from "./assets/animation.gif";
import metamask from "./assets/metamask.svg";
import nalikes from "./assets/nalikes.svg";

import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { providerOptions } from "./provideroptions.js";
import { toHex, truncateAddress } from "./utils";
import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';

import allowlistAddresses from './addresses/allowlist.json';

// Goerli
// import Contract_ABI from "./Contract_ABI_goerli.json";
// const Contract_Address = "0x01966bB13aC284CbAC2D5D2B3ceE9accc58093Eb"

// Mainnet
import Contract_ABI from "./Contract_ABI.json";
const Contract_Address = "0xced6b796b07fcfbf6f158af0150d4c5dfef89e7f"

const web3Modal = new Web3Modal({
  cacheProvider: false, // optional
  providerOptions: providerOptions, // required
  disableInjectedProvider: false
});

const m_network = "mainnet"
// mainnet = 1; goerli = 5
const m_chain = 1
const m_infura = "6de59dc14a914133b494e6208a6b914f"

function App() {
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [signer, setSigner] = useState();

  const [account, setAccount] = useState();
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  const [nftContract, setNftContract] = useState(null);

  const [totalMinted, setTotalMinted] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [cost, setCost] = useState(0);
  const [accountBalance, setAccountBalance] = useState(0);

  let mintAmount = 1;

  const [paused, setPaused] = useState(true);
  const [allowlistMintEnabled, setAllowlistMintEnabled] = useState(false);
  const [publicMintEnabled, setPublicMintEnabled] = useState(false);

  const [allowlistMerkleTree, setAllowlistMerkleTree] = useState(null);
  const [isAllowedToMint, setIsAllowedToMint] = useState(false);

  // EFFECTS

  useEffect(() => {
    window.addEventListener('load', () => {
      document.getElementById('preloading').style.display = 'none';
    });

    web3Modal.clearCachedProvider();
    initializeApp();
    // if (web3Modal.cachedProvider) {
    //   console.log("Loaded cached", web3Modal.cachedProvider);
    //   connectWallet();
    // }
  }, []);

  useEffect(() => {
    if (provider?.on) {

      const handleAccountsChanged = (accounts) => {
        console.log("accountsChanged", accounts);
        if (accounts) setAccount(accounts[0]);
      };

      const handleChainChanged = (_hexChainId) => {
        console.log("chainChanged", _hexChainId);
        switchNetwork();
      };

      const handleDisconnect = (err) => {
        console.log("disconnect", err);
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);

  useEffect(() => {
    if (isWalletConnected) {
      loadAppData();

      console.log(allowlistMerkleTree)

      if (allowlistMerkleTree != null && allowlistMintEnabled == true) {
        setIsAllowedToMint(treeContainsAddress(allowlistMerkleTree));
      }
    }
  }, [isWalletConnected, account])

  // CONNECTION

  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const signer = library.getSigner();
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();

      setProvider(provider);
      setLibrary(library);
      if (accounts) setAccount(accounts[0]);
      setSigner(signer);

      if (network.chainId !== m_chain)
        await switchNetwork();

      setIsWalletConnected(true);

      document.querySelector("#inputButton").style.display = 'flex';
      document.querySelector(".disconnectButton").style.display = 'flex';

      console.log("Connected.")

    } catch (err) {
      console.log(err);
    }
  }

  const disconnect = async () => {
    await web3Modal.clearCachedProvider();

    setAccount();
    setAccountBalance(0);
    setIsWalletConnected(false);
    setIsAllowedToMint(false);

    document.querySelector("#inputButton").style.display = 'none';
    document.querySelector(".disconnectButton").style.display = 'none';

    mintAmount = 1;

    console.log("Disconnected.")
  }

  // APP

  const initializeApp = async () => {
    const temp_provider = new ethers.providers.InfuraProvider(m_network, m_infura)
    const tempInstance = await new ethers.Contract(Contract_Address, Contract_ABI, temp_provider);

    // Paused
    tempInstance.functions.paused().then((response) => {
      let state = response[0];
      console.log("(INIT) Contract Paused: " + state);
      setPaused(state);
    });

    // AllowlistEnabled
    tempInstance.functions.allowlistMintEnabled().then((response) => {
      let state = response[0];
      console.log("(INIT) Allowlist Mint Enabled: " + state);
      setAllowlistMintEnabled(state);
    });

    // PublicMintEnabled
    tempInstance.functions.publicMintEnabled().then((response) => {
      let state = response[0];
      console.log("(INIT) Public Mint Enabled: " + state);
      setPublicMintEnabled(state);
    });


    // TotalMinted
    tempInstance.functions.totalSupply().then((response) => {
      let bal = parseInt(response[0]["_hex"]);
      console.log("(INIT) Total Minted: " + bal);
      setTotalMinted(bal);
    });

    // MaxSupply
    tempInstance.functions.maxSupply().then((response) => {
      let bal = parseInt(response[0]["_hex"]);
      console.log("(INIT) Max Supply: " + bal);
      setMaxSupply(bal);
    });

    // Cost
    tempInstance.functions.cost().then((response) => {
      let costInWei = parseInt(response[0]["_hex"]);
      console.log("(INIT) Mint cost (wei) " + costInWei)
      setCost(costInWei);
    });

    // Generate Merkle Trees
    console.log("Generating Allowlist Merkle Tree...");
    setAllowlistMerkleTree(generateMerkleTree(allowlistAddresses));
  }

  const loadAppData = async () => {
    if (!isWalletConnected)
      return;
    try {
      console.log("Loading app data. Account:", account);

      // Account Balance
      library.getBalance(account).then((response) => {
        const bal = parseFloat(ethers.utils.formatEther(response)).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0]
        console.log("Account balance: " + bal + " ETH");
        setAccountBalance(response.toString());
      });

      let contractInstance = await new ethers.Contract(Contract_Address, Contract_ABI, signer);
      setNftContract(contractInstance);

      // Paused
      contractInstance.functions.paused().then((response) => {
        let state = response[0];
        console.log("Contract Paused: " + state);
        setPaused(state);
      });

      // AllowlistEnabled
      contractInstance.functions.allowlistMintEnabled().then((response) => {
        let state = response[0];
        console.log("Allowlist Mint Enabled: " + state);
        setAllowlistMintEnabled(state);
      });

      // PublicMintEnabled
      contractInstance.functions.publicMintEnabled().then((response) => {
        let state = response[0];
        console.log("Public Mint Enabled: " + state);
        setPublicMintEnabled(state);
      });

      // TotalMinted
      contractInstance.functions.totalSupply().then((response) => {
        let bal = parseInt(response[0]["_hex"]);
        console.log("Total Minted: " + bal);
        setTotalMinted(bal);
      });

      // MaxSupply
      contractInstance.functions.maxSupply().then((response) => {
        let bal = parseInt(response[0]["_hex"]);
        console.log("Max Supply: " + bal);
        setMaxSupply(bal);
      });

      // Cost
      contractInstance.functions.cost().then((response) => {
        let costInWei = parseInt(response[0]["_hex"]);
        console.log("Mint cost (wei): " + costInWei)
        setCost(costInWei);
      });

    } catch (err) {
      let message = JSON.parse(err)["message"];
      console.log(message);
    }
  }

  // UTILITIES

  function enableProcessingStatus () {
    document.querySelector("#processingStatus").style.display = "block";
    document.querySelector("#failedStatus").style.display = "none";
    document.querySelector("#successfulStatus").style.display = "none";
  }

  function enableFailedStatus () {
    document.querySelector("#processingStatus").style.display = "none";
    document.querySelector("#failedStatus").style.display = "block";
    document.querySelector("#successfulStatus").style.display = "none";
  }

  function enableSuccessfulStatus () {
    document.querySelector("#processingStatus").style.display = "none";
    document.querySelector("#failedStatus").style.display = "none";
    document.querySelector("#successfulStatus").style.display = "block";
  }

  const switchNetwork = async () => {
    await library.provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: toHex(m_chain) }]
    });
    console.log("Switched network.");
    connectWallet();
  }

  const updateMintAmount = (counter) => {
    let currentValue = parseInt(document.getElementById("quantity").value);
    currentValue += parseInt(counter);
    if(currentValue <= 1) currentValue = 1;
    if(currentValue >= 8) currentValue = 8;

    mintAmount = currentValue;
    console.log({mintAmount})

    document.getElementById("quantity").value = currentValue;
    document.getElementById("quantity").placeholder = currentValue;
  }

  // MERKLE TREE FUNCTIONS

  const generateMerkleTree = (_addresses) => {
    let leafNodes = _addresses.map(addr => keccak256(addr));
    let tree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
    let rootHash = tree.getRoot();
    console.log('Root Hash\n', '0x' + rootHash.toString('hex'));
    return tree;
  }

  function treeContainsAddress(_merkleTree) {
    if (_merkleTree != null) {
      let state = _merkleTree.getLeafIndex(Buffer.from(keccak256(account))) >= 0;
      console.log("Allowlisted: " + state);
      return state;
    }

    // setIsWhitelisted(merkleTree.getLeafIndex(Buffer.from(keccak256(account))) >= 0);
    // const verified = tree.verify(proof, leaves[2], root)
  };

  const getProofForAddress = (_merkleTree) => {
    let proof = _merkleTree.getHexProof(keccak256(account));
    console.log(proof);
    return proof;
  };

  // MINT FUNCTIONS

  const allowlistMint = async () => {

    if (nftContract !== undefined) {

      document.querySelector("#popup").style.display = "flex";
      document.querySelector("#status").innerHTML = "Transaction Processing...";
      document.querySelector("#message").innerHTML = "";
      enableProcessingStatus();

      mintAmount = parseInt(mintAmount);
      if (mintAmount < 1 || mintAmount > 8) mintAmount = 1;

      let totalCost = cost * mintAmount;

      let checksPassed = false;
      console.log({ checksPassed })
      let errMessage = await nftContract.functions.allowlistMintedBalance(account).then((response) => {
        let balance = parseInt(response[0]["_hex"]);
        console.log("Allowlist Minted Balance: " + balance);

        if (balance + mintAmount > 30) {
          console.log("Allowlist Mint Balance Exceeded.");
          checksPassed = false;
          return "Allowlist Mint Balance Exceeded.";
        }

        if (accountBalance < totalCost) {
          console.log("Insufficient Funds.");
          checksPassed = false;
          return "Insufficient Funds.";
        }

        checksPassed = true;
        return;
      });
      console.log({ checksPassed })
      if (!checksPassed) {
        document.querySelector("#status").innerHTML = "Transaction Failed!";
        document.querySelector("#message").innerHTML = errMessage;
        enableFailedStatus(); 
        return;
      }

      let proof = getProofForAddress(allowlistMerkleTree);
      console.log("Minting " + mintAmount + " NFT(s), Cost: " + ethers.utils.formatEther(totalCost.toString()) + " ETH");

      try {
        await nftContract.allowlistMint(mintAmount, proof, { from: account, value: totalCost.toString() }).then((res) => {
          document.querySelector("#status").innerHTML = "Transaction Sent!";
          document.querySelector("#message").innerHTML = "View Transaction Status on <a target='_blank' href='https://etherscan.io/tx/" + res.hash + "'>Etherscan</a>"
          enableSuccessfulStatus();
          console.log(res);
        });
      } catch (err) {
        document.querySelector("#status").innerHTML = "Transaction Failed!";
        document.querySelector("#message").innerHTML = "Your transaction failed, Error Code: " + err.code;
        enableFailedStatus();
        console.error("------ TRANSACTION ERROR ------");
        console.error(err);
        console.error("------ TRANSACTION ERROR ------");
      }
    }
  }

  const publicMint = async () => {

    if (nftContract !== undefined) {

      document.querySelector("#popup").style.display = "flex";
      document.querySelector("#status").innerHTML = "Transaction Processing...";
      document.querySelector("#message").innerHTML = "";
      enableProcessingStatus();

      mintAmount = parseInt(mintAmount);
      if (mintAmount < 1 || mintAmount > 8) mintAmount = 1;

      let totalCost = cost * mintAmount;

      let checksPassed = false;
      let errMessage = "";

      if (accountBalance < totalCost) {
        console.log("Insufficient Funds.");
        checksPassed = false;
        errMessage = "Insufficient Funds.";
      }
      else {
        checksPassed = true;
      }

      console.log({ checksPassed })
      if (!checksPassed) {
        document.querySelector("#status").innerHTML = "Transaction Failed!"
        document.querySelector("#message").innerHTML = errMessage;
        enableFailedStatus(); 
        return;
      }

      console.log("Minting " + mintAmount + " NFT(s), Cost: " + ethers.utils.formatEther(totalCost.toString()) + " ETH");

      try {
        await nftContract.mint(mintAmount, { from: account, value: totalCost.toString() }).then((res) => {
          enableSuccessfulStatus();
          document.querySelector("#status").innerHTML = "Transaction Sent!"
          document.querySelector("#message").innerHTML = "View Transaction Status on <a target='_blank' href='https://etherscan.io/tx/" + res.hash + "'>Etherscan</a>"
          console.log(res);
        });
      } catch (err) {
        enableFailedStatus();
        document.querySelector("#status").innerHTML = "Transaction Failed!";
        document.querySelector("#message").innerHTML = "Your transaction failed, Error Code: " + err.code;
        console.error("------ TRANSACTION ERROR ------");
        console.error(err);
        console.error("------ TRANSACTION ERROR ------");
      }
    }
  }

  const NFTPayPopup = () => {
    const el = document.getElementById('nftpaypopup');
    if(el.style.display === "none")
      el.style.display = "flex";
    else
      el.style.display = "none";
  }

  return (
    <div className='main' >
      <div className='backgroundImage'></div>
      {/* PRELOADER */}
      <div className="preloading popup" id="preloading">
        <img src={logo} alt="" />
      </div>

      <div id='nftpaypopup' className="nftpaypopup" style={{display: "none"}}>
        <div className="nftpayinner">
          <h1 className="closeNFTPay" onClick={() => {document.getElementById('nftpaypopup').style.display = 'none';}}>x</h1>
          {/* Goerli */}
          {/* <iframe className="nftpay_iframe" title='NFTPay' src="https://sandbox.nftpay.xyz/iframe/iframe_pay/f2f7503a-b9dc-4cc4-8787-966c64b4e761?"></iframe> */}
          {/* Mainnet */}
          <iframe className="nftpay_iframe" title='NFTPay' src="https://payments.nftpay.xyz/iframe/iframe_pay/33935121-67a9-4f12-8ee0-2b3285434d14?"></iframe>
        </div>
      </div>

      {/* POPUP */}
      <div className="popup" id="popup">
        <div className="popupBox">
          <div className="popupHeading">
            <h1 className="statusHeading"></h1>
            <h1 className="closeX" onClick={() => {document.getElementById('popup').style.display = 'none';}}>X</h1>
          </div>
          <div id="failedStatus" ><center><img src={failed}/></center></div>
          <div id="successfulStatus" ><center><img src={successful}/></center></div>
          <div id="processingStatus" ><center><div className="spinner"></div></center></div>
          <h1 id="status"></h1>
          <h2 id="message"></h2>
        </div>
      </div>

      {/* NAV BAR */}
      <nav>
        <div className="row">
          <div className="col">
            <img id="navImg" src={logo_gif} alt="Edition 22 NFT" />
          </div>
        </div>
      </nav>

      {/* MAIN SECTION */}
      <section className="mainSection">
        <div className="mainHeading" id="mainHeading">
          <h1>Edition 22 GENESIS MINT</h1>
        </div>
        <div className="stats">
            <div className="subheading">
                <h2 className="title">
                    Supply
                </h2>
                <h2 className="value">
                  {totalMinted} / {maxSupply}
                </h2>
            </div>
            <div className="subheading">
                <h2 className="title">
                    Price
                </h2>
                <h2 className="value">
                    {ethers.utils.formatEther(cost.toString())} ETH
                </h2>
            </div>
        </div>
        <div className="canMint" hidden={!(!isAllowedToMint && allowlistMintEnabled && isWalletConnected)}>
          Cannot participate in Allowlist Mint.
        </div>
        <div className="nftImage">
            <img id="mainNftImage" src={hidden} alt="Edition 22 NFT"/>
            <div className="quantity buttons_added" id="inputButton">
                <input disabled={paused || (!allowlistMintEnabled && !publicMintEnabled)} type="button" value="-" className="minus" onClick={() => {updateMintAmount(-1)}}/>
                <input disabled={paused || (!allowlistMintEnabled && !publicMintEnabled)}
                    type="number" step="1" min="1" max="8" name="quantity" id="quantity" value="1" placeholder="1" title="Qty" className="input-text qty text" 
                    size="4" pattern="" inputmode=""/>
                <input disabled={paused || (!allowlistMintEnabled && !publicMintEnabled)} type="button" value="+" className="plus" onClick={() => {updateMintAmount(1)}}/>
            </div>
        </div>
        <a className="disconnectButton" onClick={() => {disconnect();}}>
          Connected: {truncateAddress(account)}
        </a>
        <button className="mintButton" hidden={isWalletConnected} onClick={() => {connectWallet();}}>
          <div className="cryptoButton">
            <img src={metamask}/> &nbsp; <h3>Connect Wallet</h3>
          </div>
        </button>

        <button className="mintButton" hidden={!allowlistMintEnabled || !isWalletConnected} disabled={!isAllowedToMint} onClick={() => {allowlistMint();}}>
          <div className="cryptoButton">
            <img src={metamask}/> &nbsp; Mint
          </div>
        </button>

        <button className="mintButton" hidden={!publicMintEnabled || !isWalletConnected} onClick={() => {publicMint();}}>
          <div className="cryptoButton">
            <img src={metamask}/> &nbsp; Mint
          </div>
        </button>
        <button hidden={!publicMintEnabled} onClick={() => {NFTPayPopup();}} className="mintButton" id="card"> Buy with Credit Card</button>
        
        <a href="https://www.nalikes.com/" target="_blank">
            <h3 className='nalikesFooter'>Powered By&nbsp; <img src={nalikes}/> </h3>
          </a>

      </section>

      {/* FOOTER SECTION */}
      {/* <section className="nalikesFooter">
        <center>
          <a href="https://www.nalikes.com/" target="_blank">
            <h3>Powered By&nbsp; <img src={nalikes}/> </h3>
          </a>
        </center>
      </section> */}
    </div>
  );
}
export default App;